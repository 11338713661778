import colors from 'tailwindcss/colors';
import tailwindScrollbarHide from 'tailwind-scrollbar-hide';
import tailwindTypography from '@tailwindcss/typography';
import daisyui from 'daisyui';

const primaryPalette = {};
for (var shade of [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]) {
    const colorString = `rgba(var(--palette-primary-${shade}-no-rgb), <alpha-value>)`;
    primaryPalette[shade] = colorString;
}

/** @type {import('tailwindcss').Config} */
export default {
    content: ['./src/**/*.{html,ts}'],
    safelist: [
        { pattern: /bg-(\w+)-(\d00)/, variants: ['dark', 'hover'] },
        { pattern: /border-(\w+)-(\d00)/, variants: ['dark', 'hover'] },
        { pattern: /border-(\w+)-(w+)/, variants: ['dark', 'hover'] },
        { pattern: /text-(\w+)-(\d00)/, variants: ['dark', 'hover'] },
        'group-hover:animate-bounce',
        'group-hover:animate-ping',
        'group-hover:animate-pulse',
        'group-hover:animate-spin',
        'blur-sm',
    ],
    theme: {
        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            black: colors.black,
            emerald: colors.emerald,
            gray: colors.neutral,
            green: colors.green,
            orange: colors.orange,
            red: colors.red,
            sky: colors.sky,
            white: colors.white,
            yellow: colors.yellow,
        },
        fontFamily: {
            sans: 'Roboto, sans-serif',
            mono: '"Fira Code", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
            title: 'Montserrat, sans-serif',
        },
        extend: {
            colors: {
                primary: primaryPalette,
                gray: {
                    750: '#323232',
                },
            },
            typography: (theme) => ({
                DEFAULT: {
                    css: {
                        // From https://futurestud.io/tutorials/tailwind-css-remove-backticks-around-inline-code
                        'code::before': { content: 'none' },
                        'code::after': { content: 'none' },
                        code: {
                            color: theme('colors.gray.200'),
                            backgroundColor: theme('colors.gray.800'),
                            borderRadius: theme('borderRadius.DEFAULT'),
                            padding: theme('spacing.1'),
                            paddingLeft: theme('spacing.2'),
                            paddingRight: theme('spacing.2'),
                        },
                        '.isDarkTheme code': {
                            // Match the `pre` background color.
                            backgroundColor: 'rgb(0 0 0 / 50%)',
                        },
                    },
                },
            }),
        },
    },
    darkMode: ['class', '.isDarkTheme'],
    plugins: [ tailwindScrollbarHide, tailwindTypography, daisyui ],
    daisyui: {
        themes: [
            {
                'sz-light': {
                    neutral: '#404040',
                    'base-100': '#ffffff',
                    info: '#0369a1',
                    success: '#15803d',
                    warning: '#a16207',
                    error: '#b91c1c',
                },
                'sz-dark': {
                    neutral: '#f5f5f5',
                    'base-100': '#171717',
                    info: '#7dd3fc',
                    success: '#86efac',
                    warning: '#fde047',
                    error: '#fca5a5',
                },
            },
        ],
        darkTheme: 'sz-dark',
        base: false,
        styled: true,
        logs: false,
    },
};
